/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import("./src/dealer-theme/theme/css/index.css");
import React from 'react';
import {DataContextProvider}  from './src/dealer-theme/core/store/DataContext';
import themeConfig from './src/dealer-theme/theme/config'

const wrapRootElement = ({element}) => <DataContextProvider themeConfig={themeConfig}>{element}</DataContextProvider>


const scrollTo = (id) => () => {
    let offset = 0;
    const el = document.querySelector(id);

    try {
        const stickies = document.getElementsByClassName("sticky");
        for (let key in stickies) {
            const element = stickies[key];
            if (element && element.offsetHeight && element.offsetHeight > 0) {
                offset += element.offsetHeight
            }
        }
    }catch(ex) {}

    if (el) return window.scrollTo(0, el.offsetTop - offset);
    return false
};


const onRouteUpdate = ({location: {hash}}) => {
    if (hash) {
        window.setTimeout(scrollTo(hash), 200)
    }
};

export { wrapRootElement, onRouteUpdate };
